import { useState } from 'react'
import { Box, Text, Button, Image, Stack, useDisclosure, Heading, Grid, GridItem } from '@chakra-ui/react'
import { QRWithLogo } from './QRCode'
import ModalContainer from '../shared/ModalContainer'
import { useTranslation } from 'react-i18next'
import KioskManualNumber from './KioskManualNumber'
import Language from '../shared/Language'
import Footer from '../shared/Footer'
import { db, GroupData, QueueData, resolveQueueNumberWithPrefix } from '@flowby/shared-firebase'
const logo = '/android-chrome-512x512.png'

export default function QueueKioskView({ store, queue, queueData, groupsData, printerPortName }: {
  store: string
  queue: string
  queueData: QueueData
  groupsData: GroupData[]
  printerPortName: string | null
}) {
  const { t } = useTranslation()
  const [logoError, setLogoError] = useState(false)
  const manualQueueNumberDisclosure = useDisclosure()
  const queueClosed = queueData.state.status === 'closed' || queueData.state.status === 'closing'

  const [manualQueueNumber, setManualQueueNumber] = useState<number | null>(null)
  const [queueNumberId, setQueueNumberId] = useState<string | null>(null)
  async function getManual(store: string, queue: string) {
    setManualQueueNumber(null)
    const { queueNumber, id } = await db.createQueueNumber(store, queue, true, true)
    setManualQueueNumber(queueNumber)
    setQueueNumberId(id)
  }

  const modalContent = (
    <KioskManualNumber
      store={store}
      printerPortName={printerPortName}
      manualQueueNumber={manualQueueNumber ? resolveQueueNumberWithPrefix(manualQueueNumber, queueData, groupsData) : '...'}
      queueName={queueData.displayName}
      queueNumberId={queueNumberId}
      queue={queue}
      onFinish={() => {
        manualQueueNumberDisclosure.onClose()
      }}
    />
  )

  return (
    <Stack data-testid="kiosk-view" minH="100%" justify="space-between">
      <Grid pt={4} px={4} pb={0} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)" gap={2}>
        <GridItem colSpan={1} pt={2} pl={2}>
          <Language size="md" store={store} />
        </GridItem>
        <GridItem colSpan={2} mb={-2} pt={1}>
          <Box position="relative" w="100%" h="100%" textAlign="center" alignItems="center" pt={1} pb={2}>
            {!logoError && (
              <Image
                role="presentation"
                maxH={28}
                margin="auto"
                fit="contain"
                src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === 'dev' ? 'flowby-io-dev' : 'flowby-io'}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
                onError={() => setLogoError(true)}
              />
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
      </Grid>
      <ModalContainer
        // size="2xl"
        isOpen={manualQueueNumberDisclosure.isOpen}
        onClose={() => {
          manualQueueNumberDisclosure.onClose()
        }}
        closeOnOverlayClick={false}
        closeButton={false}
        header={''}
        content={modalContent}
      />
      <Box pr={8} pl={8}>
        <Stack pb={{ base: 2 }} spacing={8} alignItems="center" textAlign="center">
          {!queueClosed &&
            <>
              <Heading lineHeight={0.9} textAlign="center" fontSize="6xl">
                {t('kiosk-enter-queue-here')}
              </Heading>
              <Text fontSize="3xl">{t('kiosk-scan-qr-code')}</Text>
              <Box>
                <QRWithLogo
                  size={350}
                  url={`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/q/${queue}?kiosk=true`}
                  logo={logo}
                />
              </Box>
              <Text fontSize="3xl">
                {t('kiosk-click-manual-button')}
              </Text>
              <Button
                height={20}
                fontSize={36}
                pr={10}
                pl={10}
                onClick={() => {
                  getManual(store, queue)
                  manualQueueNumberDisclosure.onOpen()
                }}
              >
                {t('manual-queue-number')}
              </Button>
            </>
          }
          {queueClosed &&
            <Box px={8} pb={20}>
              <Heading pb={4} textAlign="center" fontSize="6xl">
                {t('queue-closed')}
              </Heading>
              <Text fontSize="3xl">{t('queue-closed-try-later')}</Text>
            </Box>
          }
        </Stack>
      </Box>
      <Footer size="xl" />
    </Stack>
  )
}
