import { Box } from '@chakra-ui/react'
import i18next from 'i18next'
import Select, { components } from 'react-select'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { theme } from '@flowby/shared-general'
import { useDocData } from '../../libs/firebaseHooks'
import { db } from '@flowby/shared-firebase'
import { useEffect, useState } from 'react'

type Option = { label: string, value: string, flag: any }

const defaultLangOptions: Option[] = [
  {
    label: "English",
    value: "en",
    flag: '/flags/en.svg',
  },
  {
    label: "Swedish",
    value: "sv",
    flag: '/flags/sv.svg',
  },
]

const getDefaultOption = () => defaultLangOptions.filter(obj => obj.value === i18next.language)[0]

const optionBox = (flag: any, label: string, showLabel = true, size: string) => (
  <Box className="plausible-event-name=Language+Change">
    <img width={size === 'sm' ? 32 : 48} style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 4 }} src={flag} alt={label} />
    {showLabel && <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>{label}</span>}
    {!showLabel && <ChevronDownIcon color="black" fontSize={18} marginLeft={size === 'sm' ? '-1px' : 0} />}
  </Box>
)

const SingleValue = ({ ...props }): any => (
  <components.SingleValue {...props}>
    {optionBox(props.data.flag, props.data.label, false, props.size)}
  </components.SingleValue>
)

const Input = (props: any) => {
  return <components.Input readOnly={true} {...props} />
}

export default function Language({ size = 'sm', store }: {
  size?: 'sm' | 'md' | 'lg',
  store: string
}) {
  const [storeData, storeLoading] = useDocData(db.getStoreRef(store), [store])
  const [langOptions, setLangOptions] = useState<Option[]>([])

  useEffect(() => {
    if (storeData && storeData.supportedLangs) {
      const supportedLangs = storeData?.supportedLangs as string[]
      const newLangOptions = defaultLangOptions.filter(obj => supportedLangs.includes(obj.value))
      const defaultOption = getDefaultOption()
      if (defaultOption && !newLangOptions.includes(defaultOption)) {
        i18next.changeLanguage(storeData.lang)
      }
      setLangOptions(newLangOptions)
    }
  }, [storeData])

  return !storeLoading && langOptions.length > 1 ? (
    <Box>
      <Select
        aria-label="Language selector"
        styles={{
          option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? theme.colors.colors.green['500'] : 'none',
            '&:hover': {
              backgroundColor: state.isSelected ? theme.colors.colors.green['500'] : theme.colors.colors.green['50']
            },
          }),
          singleValue: (base: any) => ({
            ...base
          }),
          control: (base: any) => ({
            ...base,
            boxShadow: 'none',
            border: '2px solid black',
            width: size === 'sm' ? 64 : 86,
            height: size === 'sm' ? 40 : 54,
            '&:hover': {
              border: '2px solid black'
            },
          }),
          valueContainer: (base: any) => ({
            ...base,
            paddingLeft: size === 'sm' ? 4 : 8,
            paddingRight: 0,
          })
        }}
        isClearable={false}
        isSearchable={true}
        onChange={(option: Option) => {
          if (option && option.value) {
            i18next.changeLanguage(option.value)
          }
        }}
        options={langOptions}
        value={getDefaultOption()}
        formatOptionLabel={(country: Option) => optionBox(country.flag, country.label, true, size)}
        components={{
          Input: (props: any) => Input(props),
          SingleValue: (props: any) => SingleValue({ ...props, size }),
          IndicatorsContainer: () => null
        }}
      />
    </Box>
  ) : null
}
