import { useState } from 'react'
import { db, GroupData, QueueData, resolveQueueNumberWithPrefix } from '@flowby/shared-firebase'
import { Box, Text, Button, Image, Stack, useDisclosure, Heading, Grid, GridItem } from '@chakra-ui/react'
import { QRWithLogo } from './QRCode'
import ModalContainer from '../shared/ModalContainer'
import { useTranslation } from 'react-i18next'
import QueueSelector from './QueueSelector'
import KioskManualNumber from './KioskManualNumber'
import Language from '../shared/Language'
import Footer from '../shared/Footer'
const logo = '/android-chrome-512x512.png'

const resolveQueueNumberWithPrefixWrapper = (
  queueNumber: number,
  queueData: QueueData | undefined,
  groupsData: GroupData[]
) => {
  return queueData ? resolveQueueNumberWithPrefix(queueNumber, queueData, groupsData) : queueNumber
}

export default function GroupKioskView({
  store,
  group,
  groupData,
  queuesData,
  printerPortName
}: {
  store: string
  group: string
  groupData: GroupData
  queuesData: QueueData[]
  printerPortName: string | null
}) {
  const { t } = useTranslation()
  const [logoError, setLogoError] = useState(false)
  const [modalState, setModalState] = useState('queueSelector')
  const [queue, setQueue] = useState<string | null>(null)
  const [queueNumberId, setQueueNumberId] = useState<string | null>(null)
  const manualQueueNumberDisclosure = useDisclosure()
  const queuesClosed = !queuesData.some((queueData) => {
    if (groupData.queues.includes(queueData.shortName)) {
      return queueData.state.status === 'open'
    }
  })
  const [manualQueueNumber, setManualQueueNumber] = useState<number | null>(null)
  async function getManual(store: string, queue: string) {
    setManualQueueNumber(null)
    setQueue(null)
    setQueueNumberId(null)
    const { queueNumber, id } = await db.createQueueNumber(store, queue, true, true)
    setManualQueueNumber(queueNumber)
    setQueueNumberId(id)
    setQueue(queue)
  }

  const modalContent =
    modalState === 'queueSelector' ? (
      <QueueSelector
        groupData={groupData}
        queuesData={queuesData}
        onFinish={(queue: string) => {
          getManual(store, queue)
          setModalState('manualNumber')
        }}
      />
    ) : (
      <KioskManualNumber
        store={store}
        printerPortName={printerPortName}
        manualQueueNumber={manualQueueNumber && queue ?
          resolveQueueNumberWithPrefixWrapper(
            manualQueueNumber,
            queuesData.find((q) => q.shortName === queue),
            [groupData]
          )
          : '...'}
        onFinish={() => {
          setModalState('queueSelector')
          manualQueueNumberDisclosure.onClose()
        }}
        queueName={queuesData.find((queueData) => queueData.shortName === queue)?.displayName || null}
        queueNumberId={queueNumberId}
        queue={queue}
      />
    )

  return (
    <Stack id="kiosk-group" minH="100%" justify="space-between">
      <Grid pt={4} px={4} pb={0} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)" gap={2}>
        <GridItem colSpan={1} pt={2} pl={2}>
          <Language size="md" store={store} />
        </GridItem>
        <GridItem colSpan={2} mb={-2} pt={1}>
          <Box position="relative" w="100%" h="100%" textAlign="center" alignItems="center" pt={1} pb={2}>
            {!logoError && (
              <Image
                role="presentation"
                maxH={28}
                margin="auto"
                fit="contain"
                src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === 'dev' ? 'flowby-io-dev' : 'flowby-io'}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
                onError={() => setLogoError(true)}
              />
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
      </Grid>
      <ModalContainer
        // size="2xl"
        closeOnOverlayClick={modalState === 'manualNumber' ? false : true}
        closeButton={false}
        isOpen={manualQueueNumberDisclosure.isOpen}
        onClose={() => {
          setModalState('queueSelector')
          manualQueueNumberDisclosure.onClose()
        }}
        header={''}
        content={modalContent}
      />
      <Box pr={8} pl={8}>
        <Stack pb={{ base: 2 }} spacing={8} alignItems="center" textAlign="center">
          {!queuesClosed &&
            <>
              <Heading lineHeight={0.9} textAlign="center" fontSize="6xl">
                {t('kiosk-enter-queue-here')}
              </Heading>
              <Text fontSize="3xl">{t('kiosk-scan-qr-code')}</Text>
              <Box>
                <QRWithLogo
                  size={350}
                  url={`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/g/${group}?kiosk=true`}
                  logo={logo}
                />
              </Box>
              <Text fontSize="3xl">
                {t('kiosk-click-manual-button')}
              </Text>
              <Button id="kiosk-group-manual-number" height={20} fontSize={36} pr={10} pl={10} onClick={manualQueueNumberDisclosure.onOpen}>
                {t('manual-queue-number')}
              </Button>
            </>
          }
          {queuesClosed &&
            <Box px={8} pb={20}>
              <Heading pb={4} textAlign="center" fontSize="6xl">
                {t('queue-closed')}
              </Heading>
              <Text fontSize="3xl">{t('queue-closed-try-later')}</Text>
            </Box>
          }
        </Stack>
      </Box>
      <Footer size="xl" />
    </Stack>
  )
}
